import {createApp, reactive} from "petite-vue";
import {debounce} from "../utils/debounce";

declare global {
    const archiveData: ArchiveData
}

export default function initArchiveProgram(): void {
    const archiveGrid = document.querySelector('[data-program-grid]')
    if (!archiveGrid || !archiveData) return

    async function setFilterQuery() {
        this.isLoading && fetchController.abort()
        this.scrollIntoView = true
        this.setQuery(this.selectedParams , true, true)
    }

    const fetchController = new AbortController(),
        signal = fetchController.signal

    const archive: ArchiveStore = reactive({
        ...archiveData,
        appendPosts: false,
        scrollIntoView: false,
        showAllOptions: false,
        isLoading: false,
        selectedParams: Object.values(archiveData.filterParams ?? {})
            .reduce<QueryParams>((params, parameter) => { 
                return {... params, [parameter.key]: archiveData?.query[parameter.key] 
                        ? archiveData?.query[parameter.key].split(",") : [] }
            }, {}),
        get selectedParamsTags() {
            let tags: FilterParamTag[] = []

            Object.values<FilterParam>(this.filterParams).forEach(param => {
                Object.entries(param.options).forEach(([key, label]) => {
                    this.selectedParams[param.key].includes(key) && tags.push({
                        title: param.label,
                        parameter: param.key,
                        label: label,
                        key: key
                    })
                })
            })

            return tags
        },

        setQuery(params = {}, resetPager = false, resetQuery = false) {
            const query = params ? {
                ...(resetQuery ? {} : this.query),
                ...params,
                ...(resetPager ? {strana: 1} : {})
            } : {}

            this.getArchive(query)
        },
        removeParameter(key: string, value: string) {
            if (!this.selectedParams.hasOwnProperty(key)) return
            this.selectedParams[key] = Array.isArray(this.selectedParams[key])
                ? this.selectedParams[key].filter((term: string) => term !== value) : []

            this.filterPrograms()
        },
        changePage(page: number) {
            this.scrollIntoView = true
            this.setQuery({ strana: page })
        },
        loadNextPage() {
            this.appendPosts = true
            this.setQuery({ strana: this.page + 1 })
        },
        // TODO: upravit debounce o kontrolu isFetching a nastavení isLoading při prvním zavolání
        filterPrograms: debounce(setFilterQuery, 400),
        async getArchive(queryParams = {}) {
            this.isLoading = true

            const query = new URLSearchParams()
            this.queryOrder.forEach((param: string) => {
                const value: string | string[] = queryParams[param] ?? ""

                queryParams.hasOwnProperty(param) && query.append(param, Array.isArray(value)
                    ? value.sort().join(',') : queryParams[param].toString())
            });

            query.get('strana') === '1' && query.delete('strana');
            [...query.entries()].forEach(([key, value]) => {
                (value === "" || value === "default") && query.delete(key)
            })

            const queryString = [...query].length ? `?${decodeURIComponent(query.toString())}` : '',
                targetUrl = `${location.pathname}${queryString}`

            await fetch(`${this.apiUrl}${queryString}`, { signal, method: "GET" })
                .then((response) => response.json())
                .then((data) => {
                    this.programs = [...(this.appendPosts ? this.programs : []), ...data.programs]
                    this.totalCount = data.totalCount
                    this.totalCountText = data.totalCountText
                    this.page = data.page
                    this.pager = data.pager
                    this.nextPageUrl = data.nextPageUrl
                    this.query = data.query

                    this.scrollIntoView && !this.appendPosts && archiveGrid?.scrollIntoView()
                    !queryParams.hasOwnProperty('noHistory') && history.pushState({}, '', targetUrl)
                })
                .catch(e => e.name !== 'AbortError' && location.assign(targetUrl))
                .finally(() => {
                    this.isLoading = this.appendPosts = this.scrollIntoView = false
                })
        },
        closeFilter() {
            const details = archiveGrid.querySelector('details')
            if (!details) return

            details.open = false
            archiveGrid.scrollIntoView()
        }
    })

    const pager = document.querySelector('[data-pager]')
    pager?.querySelector('nav')?.addEventListener('click', e => {
        const target = e.target as HTMLElement
        if (!target.matches('a')) return

        e.preventDefault()
        const pageUrl = new URLSearchParams((new URL(target.getAttribute('href'))).search)
        archive.changePage(parseInt(pageUrl.get('strana') ?? "1"))
    })

    createApp({ archive }).mount(archiveGrid);

    (() => { // Sticky archive filter
        const stickyFilter = document.querySelector(`form[data-filter]`) as HTMLElement
        if (!stickyFilter || document.body.classList.contains('is-mobile')) return

        const gap = 32,
            header = document.querySelector('[data-header]')?.children[0] as HTMLElement

        let endScroll = window.innerHeight - stickyFilter.offsetHeight - 500,
            currPos = window.scrollY,
            screenHeight = window.innerHeight,
            stickyElementHeight = stickyFilter.offsetHeight,
            topGap = gap + 56,
            headerOffset = 0

        stickyFilter.style.top = `${topGap}px`

        const positionStickySidebar = () => {
            endScroll = window.innerHeight - stickyFilter.offsetHeight - gap
            headerOffset = header.parentElement.classList.contains('is-sticked')
                && !header.parentElement.classList.contains('is-up') ? header?.offsetHeight ?? 0 : 0

            let stickyElementTop = parseInt(stickyFilter.style.top.replace(`px;`, ``))
            if (stickyElementHeight + topGap + gap > screenHeight) {
                if (window.scrollY < currPos) {
                    if (stickyElementTop < topGap + headerOffset) {
                        stickyFilter.style.top = (stickyElementTop + currPos - window.scrollY) + `px`
                    } else if (stickyElementTop >= topGap + headerOffset && stickyElementTop != topGap + headerOffset) {
                        stickyFilter.style.top = `${topGap + headerOffset}px`
                    }
                } else {
                    if (stickyElementTop > endScroll) {
                        stickyFilter.style.top = (stickyElementTop + currPos - window.scrollY) + `px`
                    } else if (stickyElementTop < (endScroll) && stickyElementTop != endScroll) {
                        stickyFilter.style.top = `${endScroll}px`
                    }
                }
            } else {
                stickyFilter.style.top = `${topGap + headerOffset}px`
            }

            currPos = window.scrollY
        }

        const updateSticky = () => {
            screenHeight = window.innerHeight
            stickyElementHeight = stickyFilter.offsetHeight
            positionStickySidebar()
        }

        const updateStickyAfterResize = () => {
            currPos = window.scrollY
            updateSticky()
        }

        window.addEventListener("resize", updateStickyAfterResize)
        document.addEventListener("updateFilterSticky", updateStickyAfterResize)
        document.addEventListener("scroll", updateSticky, { capture: true, passive: true })
    })();
}
